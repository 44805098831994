
import { defineComponent, reactive, toRefs } from 'vue';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { ResetPasswordForm } from '@/models/Models';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';

export default defineComponent({
    props: {
        token: {
            type: String,
            required: true
        }
    },
    components: {
        LanguageSwitcher
    },
    async setup(props: any) {
        const { t } = useI18n();

        const form = reactive<ResetPasswordForm>(new ResetPasswordForm());

        const variables = reactive({
            passwordFieldType: 'password',
            confirmPasswordFieldType: 'password'
        });

        const rules = {
            password: {
                required: helpers.withMessage(t('registration.requiredValidationError'), required),
                goodPassword: helpers.withMessage(t('registration.passwordValidationError'), () => {
                    return (
                        form.password.length >= 8 &&
                        /[a-z]/.test(form.password) &&
                        /[A-Z]/.test(form.password) &&
                        /[0-9]/.test(form.password)
                    );
                })
            },
            confirmPassword: {
                required: helpers.withMessage(t('registration.requiredValidationError'), required),
                passwordsEquals: helpers.withMessage(t('registration.passwordsNotEqual'), () => {
                    return form.password == form.confirmPassword;
                })
            }
        };

        const v$ = useVuelidate(rules, form);

        const submitForm = async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                console.log(v$);
                return;
            } else {
                swal.showLoading();
                form.token = props.token;
                const resetPasswordResponse = await api.resetPassword(form);
                if (resetPasswordResponse.errorMessage)
                    swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: resetPasswordResponse.errorMessage });
                else if (resetPasswordResponse.data && resetPasswordResponse.data) {
                    swal.fire({
                        icon: 'success',
                        title: t('registration.wellDone'),
                        text: t('registration.resetPasswordSuccess'),
                        confirmButtonText: t('registration.startNow')
                    }).then(() => {
                        router.push({ name: 'login' });
                    });
                    return;
                }
            }
        };

        return {
            submitForm,
            form,
            v$,
            ...toRefs(variables)
        };
    }
});
